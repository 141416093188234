<template>
  <p :class="'status _' + status">{{ text ?? i18n.t(`statuses.trading_account.${status}`) }}</p>
</template>

<script>
import { useI18n } from 'vue-i18n';
const status = {
  props: { status: String, text: String },
  setup() {
    const i18n = useI18n();
    return { i18n };
  },
};
export default status;
</script>

<style></style>
