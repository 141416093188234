<template>
  <sdModal class="shop" centered :visible="buyTraitVisible" :onCancel="closeModal" headless :width="350">
    <div class="shop__wrapper" v-if="offers[kind]">
      <p class="shop__title">
        {{ offers[kind].title }}
      </p>
      <p class="shop__price">
        <Status status="created" :text="offers[kind].price + ' POINT'" />
      </p>
      <div class="shop__image">
        <img v-if="kind === 0" :src="require('@/static/img/banner/contest-banner.png')" alt="" />
        <img v-if="kind === 1" :src="require('@/static/img/icon/box.svg')" class="shop__svg" alt="" />
      </div>
      <div class="shop__inner">
        <!--<p class="shop__count">{{ i18n.t('start.prize.have') }} {{ pointAccount?.attributes.amount }} POINT</p>-->
        <sdButton type="success" :disabled="loading" @click="buyTrait">
          {{ i18n.t('start.prize.buy') }}
        </sdButton>
      </div>
    </div>
  </sdModal>
</template>

<script>
import { reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { message } from 'ant-design-vue';
import Status from '../elements/Status.vue';
import helper from '@/config/helpers/helper';

export default {
  props: {
    closed: {
      type: Array,
      default: null,
    },
    traits: {
      type: Number,
      default: 1,
    },
    buyTraitVisible: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const i18n = reactive(useI18n());
    const { state, dispatch } = useStore();

    const offers = computed(() => state.shop.offersData);
    const kind = computed(() => (props.traits < 1 ? 0 : 1));
    const pointAccount = computed(() => state.accounts.data[3]);
    const loading = computed(() => state.shop.loading);
    const error = computed(() => state.shop.error);

    const buyTrait = async () => {
      await dispatch('purchaseInShop', { kind: kind.value });
      if (!error.value) {
        closeModal();
      } else {
        message.error(helper().errorValidator(error.value));
      }
    };

    const closeModal = () => {
      emit('update:buyTraitVisible', false);
    };

    return {
      i18n,
      offers,
      kind,
      pointAccount,
      loading,
      buyTrait,
      closeModal,
    };
  },
  components: { Status },
};
</script>

<style></style>
